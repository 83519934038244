/* #region scrollbar styles */
/* Define scrollbar width and height */
::-webkit-scrollbar {
    width: 6px;
    height: 3px;
}

/* Track styles */
::-webkit-scrollbar-track {
    background-color: #F1F1F1;
    /* Default background color */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background-color: #888;
    /* Default color */
}

/* Handle styles */
::-webkit-scrollbar-thumb {
    background-color: #00B09B;
    /* Color on hover */
}

/* #endregion scrollbar styles */