.circle-background {
    fill: none;
    stroke: rgba(60, 64, 67, 0.2);
}

.circle-progress {
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
}

.fish-growth-container {
    display: none;
}

.fish-growth-container text {
    font-size: 14px;
    font-weight: 400;
}

.fish-progress-container:hover .fish-growth-container {
    display: block;
    animation: slowDisplay 1s ease;
}

@keyframes slowDisplay {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}