.appContantWrapper {
    margin-left: 4rem;
    max-width: 1796px;
}

@media screen and (max-width: 550px) {
    .appContantWrapper {
        margin-left: 0;
        margin-bottom: 5rem;
    }
}

@media screen and (min-width: 1796px) {
    .appContantWrapper {
        margin-left: calc((100% - (1796px)) / 2 + 4rem); /* Calculate margin on left */
        margin-right: calc((100% - (1796px)) / 2);
    }
}
