.navWrapper {
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 0.5px 0px 0px 0px rgba(60, 60, 67, 0.36);
  text-align: center;
  color: rgba(56, 56, 56, 1);
  position: fixed;
  min-height: 100vh;
  z-index: 1
}

.navWrapper div {
  height: 75vh;
  margin-top: 5rem; 
}

.navLogo {
    padding: 0.75rem;
    cursor: pointer;
}

.selectedNav {
  border-left: 2px solid rgba(56, 56, 56, 1);
}

@media screen and (max-width: 550px) {
    .navWrapper {
      min-height: auto;
      width: 100vw;
      bottom: 0;
      display: flex;
      justify-content: center;
      padding: 0.5rem;
      background: rgba(179, 179, 179, 0.82);
      color: rgba(56, 56, 56, 1);
    }

    .navWrapper div {
      height: auto;
      margin-top: 0; 
    }
    .header-logo {
      display: none;
    }

    .selectedNav {
      border-left: 0;
      border-bottom: 2px solid rgba(56, 56, 56, 1);
    }
}
