.optimal-state-icon {
    cursor: pointer;
}

.optimal-state-icon:hover {
    opacity: 0;
}

.optimal-state-icon:hover + .optimal-state {
    display: inline;
    animation: slowDisplay 1s ease;
}

.optimal-state {
    display: none;
}

@keyframes slowDisplay {
    0% {
      opacity: 0; /* Start with opacity 0 */
    }
    100% {
      opacity: 1; /* End with opacity 1 */
    }
  }